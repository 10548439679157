import { css } from "@emotion/react";
import React from "react";
import AnimatedBackground from "../AnimatedBackground/AnimatedBackground";
import EmailInput from "../EmailInput/EmailInput";
import LogoText from "../LogoText/LogoText";
// import FadeScreen from "../FadeScreen/FadeScreen";

export const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  let grayscale = 1;
  let saturate = 6;
  let blur = 12;

  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    grayscale = urlParams.get("grayscale") || grayscale;
    saturate = urlParams.get("saturate") || saturate;
    blur = urlParams.get("blur") || blur;
  }

  return (
    <div>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background: black;
        `}
      >
        <AnimatedBackground />
      </div>

      <div
        css={css`
          color: #bdbdbd;
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 23px;
          min-height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          backdrop-filter: grayscale(${grayscale}) saturate(${saturate}%)
            blur(${blur}px);
          background-color: rgba(0, 0, 0, 0.84);
          overscroll-behavior: none;
          z-index: 100;
        `}
      >
        {/* <FadeScreen> */}
        {children}

        <br />
        <br />
        <br />
        <br />

        <div
          css={css`
            padding: 0px 26px;
            max-width: 460px;
            width: 100%;
            margin: auto;
          `}
        >
          <h2
            css={css`
              text-align: center;
              margin-bottom: 36px;
            `}
          >
            NEWSLETTER
          </h2>

          <EmailInput />

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <div
            style={{
              fontSize: 16,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
            css={css`
              a {
                padding: 6px;
              }
            `}
          >
            <a
              href="https://www.instagram.com/innerchildfoundation/"
              target="_blank"
            >
              Instagram
            </a>

            <a href="mailto:info@innerchild.foundation">Contact us</a>
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <p style={{ fontSize: 11, textAlign: "center" }}>
            © 2024 <LogoText /> Foundation
          </p>

          <p style={{ fontSize: 11, textAlign: "center", color: "#919191" }}>
            made with <span style={{ color: "#fff" }}>♥</span> by{" "}
            <a
              href="https://www.linkedin.com/in/jebenois/"
              target="_blank"
              style={{
                color: "#fff",
                cursor: "pointer!important",
                zIndex: 1000,
              }}
            >
              J3B
            </a>
          </p>

          <br />
          <br />
        </div>
        {/* </FadeScreen> */}
      </div>
    </div>
  );
};
