module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Inner Child Foundation","short_name":"Inner Child Foundation","start_url":"/","icon":"static/images/logo_tmp.png","icon_options":{"purpose":"any maskable"},"display":"standalone","crossOrigin":"use-credentials","background_color":"#000000","theme_color":"#000000","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"6e878df9dbc852dfe6f95c4d6bc3388a"},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
