import React, { useState } from "react";
import { css, keyframes } from "@emotion/react";

const GOOGLE_SCRIPT_URL =
  "https://script.google.com/macros/s/AKfycbxtCfK3eZ2lZ36DdegK6zvOa5OV4n6grmSwfrXzfqUQuIQvCayzpfwI_uBUqXtSRvgTqg/exec";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const inputStyle = css`
  font-size: 14px;
  border: 1px solid black;
  border-radius: 4px;
  outline: none;
  width: 180px;
  background-color: #ffffff !important;
  padding: 5px 12px;

  &::placeholder {
    color: black;
    opacity: 1;
  }

  &:focus {
    border-color: var(--brand-color);
  }
`;

const submitButtonStyle = css`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: white;
  font-weight: bold;
  text-decoration: underline;
  transition: opacity 1000ms ease-in-out;
  margin-left: 12px;
  position: absolute;
  padding: 6px 12px;

  &:hover {
    color: var(--brand-color);
  }

  &:active {
    color: var(--brand-color);
  }
`;

const successMessageStyle = css`
  height: 91px;
  animation: ${fadeIn} 1s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmailInput = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (isValidEmail(email)) {
      setIsSubmitted(true);

      try {
        const url = `${GOOGLE_SCRIPT_URL}?email=${email}`;
        await fetch(url);
      } catch (error) {
        console.error("Error sending email:", error);
      }
    } else {
      console.log("Invalid email address");
    }
  };

  if (isSubmitted) {
    return <div css={successMessageStyle}>Thanks, we will be in touch!</div>;
  }

  return (
    <div
      css={css`
        position: relative;
        overflow: hidden;
      `}
    >
      <p
        css={css`
          text-align: center;
        `}
      >
        Sign up to receive news and updates:
      </p>

      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 24px;
        `}
      >
        <form onSubmit={handleSubmit}>
          <input
            css={inputStyle}
            type="email"
            placeholder="Email"
            aria-label="Email"
            value={email}
            onChange={(e) => {
              const value = e.target.value;
              setEmail(value);
              setShowSubmitButton(isValidEmail(value));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit(e);
              }
            }}
          />

          <button
            css={submitButtonStyle}
            type="submit"
            style={{ opacity: showSubmitButton ? 1 : 0 }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default EmailInput;
