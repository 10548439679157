import { css } from "@emotion/react";
import React from "react";

const LogoText: React.FC = () => {
  return (
    <span
      css={css`
        font-weight: 600;
        color: "#ffffff";

        &hover {
          color: "inherit";
        }
      `}
    >
      [ innerChild ]
    </span>
  );
};

export default LogoText;
