import { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { randomInt } from "../../utils/random-int";
import { placeholder_360 } from "../../images/placeholder_360";
import styled from "@emotion/styled";

interface ImageTileProps {
  width: number;
  height: number;
  index: number;
  numTiles: number;
}

const FADE_IN_DURATION = 1000; // milliseconds for fade-in duration
const IMAGE_CHANGE_DELAY = 100; // milliseconds before initiating image change
const IMAGE_CHANGE_MIN = 8000;
const IMAGE_CHANGE_MAX = 14000;
const ROTATION_DEGREES = [0, 90, 180, 270];

const IMAGE_PLACEHOLDER = placeholder_360;

const Overlay = styled.div<{ opacity: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.64); */
  z-index: 1;
  opacity: ${(props) => props.opacity};
  transition: opacity 1000ms ease-in-out;
`;

// Generates a path for a random image
const getRandomImagePath = () => {
  const index = randomInt(1, 100);

  return `/images/backgrounds/${index}.webp`;
};

// Generates random styles for rotation and mirroring
const getRandomStyles = () => {
  const rotation =
    ROTATION_DEGREES[Math.floor(Math.random() * ROTATION_DEGREES.length)];
  const scaleX = Math.random() < 0.5 ? -1 : 1;

  return {
    transform: `rotate(${rotation}deg) scaleX(${scaleX})`,
    transition: `opacity ${FADE_IN_DURATION}ms ease-in-out`,
  };
};

const ImageTile: React.FC<ImageTileProps> = ({
  width,
  height,
  index,
  numTiles,
}) => {
  const [currentImage, setCurrentImage] = useState({
    path: IMAGE_PLACEHOLDER,
    style: {},
  });
  const [nextImage, setNextImage] = useState({
    path: IMAGE_PLACEHOLDER,
    style: {},
  });
  const [nextImageOpacity, setNextImageOpacity] = useState(0);
  const [showOverlay, setShowOverlay] = useState(true);

  // Preloads an image and updates the nextImage state
  const initiateImageChange = () => {
    const nextImage = { path: getRandomImagePath(), style: getRandomStyles() };
    const image = new Image();
    image.src = nextImage.path;

    image.onload = () => {
      setNextImage(nextImage);
      setNextImageOpacity(1);

      // Wait for the fade-in to complete before setting the current image
      setTimeout(() => {
        setCurrentImage(nextImage);
        setNextImageOpacity(0);
        const delay = randomInt(
          IMAGE_CHANGE_MIN,
          IMAGE_CHANGE_MAX + numTiles * 1000
        );

        setTimeout(() => {
          initiateImageChange(); // Preload the next image
        }, delay);
      }, FADE_IN_DURATION);
    };
  };

  useEffect(() => {
    setTimeout(() => {
      initiateImageChange();
    }, randomInt(IMAGE_CHANGE_DELAY, IMAGE_CHANGE_DELAY + 1600 + 100 * index));
  }, []);

  const containerStyle = css`
    width: ${width}px;
    height: ${height}px;
    position: relative;
  `;

  const imageBaseStyle = css`
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  `;

  return (
    <div
      css={containerStyle}
      onMouseEnter={() => setShowOverlay(false)}
      onMouseLeave={() => setShowOverlay(true)}
      onTouchStart={() => {
        setShowOverlay(false);

        setTimeout(() => {
          setShowOverlay(true);
        }, 2600);
      }}
    >
      <img
        css={imageBaseStyle}
        src={currentImage.path}
        style={{ ...currentImage.style, opacity: 1 }}
        alt="Current background"
      />

      <img
        css={imageBaseStyle}
        src={nextImage.path}
        style={{ ...nextImage.style, opacity: nextImageOpacity }}
        alt="Next background"
      />

      <Overlay opacity={showOverlay ? 1 : 0} />
    </div>
  );
};

export default ImageTile;
