import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import ImageTile from "./ImageTile";

const MIN_TILE_SIZE = 60; // Minimum tile size
const MAX_TILE_SIZE = 180; // Maximum tile size

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${MIN_TILE_SIZE}px, 1fr));
  grid-auto-rows: auto;
  align-items: stretch;
  justify-items: stretch;
  width: 100%;
  height: 100%;
  gap: 0px;
  overflow: hidden;
`;

const AnimatedBackground = () => {
  const [tileSize, setTileSize] = useState(MIN_TILE_SIZE);
  const [tilesPerRow, setTilesPerRow] = useState(0);
  const [numTiles, setNumTiles] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const onResize = () => {
    // Calculate layout based on window size
    const containerWidth = window.innerWidth;
    const containerHeight = window.innerHeight;
    const minTilesPerRow = Math.ceil(containerWidth / MAX_TILE_SIZE);
    const tilesPerRow =
      minTilesPerRow % 2 !== 0 ? minTilesPerRow + 1 : minTilesPerRow;
    const newTileSize = Math.ceil(containerWidth / tilesPerRow);
    const numTilesPerColumn = Math.ceil(containerHeight / newTileSize);
    const totalTiles = tilesPerRow * numTilesPerColumn;

    setTileSize(newTileSize);
    setTilesPerRow(tilesPerRow);
    setNumTiles(totalTiles);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      onResize();
      window.addEventListener("resize", onResize);

      return () => {
        window.removeEventListener("resize", onResize);
      };
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 1000);

    return () => clearTimeout(timeout); // Clean up the timeout on component unmount
  }, []);

  return (
    <Wrapper>
      <GridContainer
        style={{
          gridTemplateColumns: `repeat(auto-fill, minmax(${tileSize}px, 1fr))`,
          width: tileSize * tilesPerRow,
        }}
      >
        {isLoaded && (
          <>
            {Array.from({ length: numTiles }, (_, index) => (
              <ImageTile
                key={index}
                height={tileSize}
                width={tileSize}
                index={index}
                numTiles={numTiles}
              />
            ))}
          </>
        )}
      </GridContainer>
    </Wrapper>
  );
};

export default AnimatedBackground;
